@import "./breakpoints", "./functions", "./variables";

@keyframes move-up-down{
    0% {
        -webkit-transform: translateX(-50%) translateY(calc(-50% + 10px));
        transform: translateX(-50%) translateY(calc(-50% + 10px));
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(calc(-50% - 10px));
        transform: translateX(-50%) translateY(calc(-50% - 10px));
    }
}

.name{
    font-size: 35px;
    letter-spacing: 1px;
    font-weight: 600;
    color: $white;
    margin: 0 0 5px;
    @include media-breakpoint-down(sm){
       font-size: 27px;
    }
  }
  
  .text {
    display: inline-block;
    font-size: 35px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 27px;
    @include media-breakpoint-down(sm){
        font-size: 27px;
     }
  }
  
  .rubberBand {
    color: #8c52dc;
    text-shadow: 2px 2px #3a988b;
    transition: all 0.5s ease-out;
    animation-name: squeeze;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
  
  @keyframes squeeze {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .Typewriter{
    padding-left: 12px;
  }
  
  #all {
    margin: 160px auto;
    width: 100px;
    height: 100px;
    -webkit-animation: grow 4s;
  /*   animation-timing-function: ease-in; */
      -webkit-animation-delay:0.5s;
    animation-fill-mode: forwards;
    /*Safari and Chrome*/
  }
  
  .script {
    font-family: Microsoft YaHei;
    color: #fff;
    font-size: 40px;
    display: block;
    position: relative;
    /*   text-align: left; */
  }
  
  .java {
    font-family: Microsoft YaHei;
    color: #fff;
    font-size: 40px;
    display: block;
    position: relative;
  }
  
  .java:before {
    content: "";
    display: block;
    position: absolute;
    left: 85px;
    width: 20px;
    height: 100%;
    background-color: red;
    -webkit-animation-delay: 2s ;
    -webkit-animation: grow 4s;
  /*   animation-timing-function: ease-in; */
     -webkit-animation-delay:0.5s;
    animation-fill-mode: forwards;
    /*Safari and Chrome*/
  }
  
  @-webkit-keyframes grow  
  {
    from {
      width: 0px;
    }
    to {
      width: 480px;
    }
  }
  

.bannerWrapper{
    height: 100vh;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('https://res.cloudinary.com/docy5lucs/image/upload/v1632340468/pw/background/bannerBackground_w1iwzn.jpg');
        top: 0;
        clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
        z-index: 1;
        @include media-breakpoint-down(md){
            clip-path: none;
        }
    }
    .content{
        position: absolute;
        top: 45%;
        z-index: 1;
        transform: translateY(-55%);
        @include media-breakpoint-down(sm){
           width: 100%;
           left: 0;
           display: block;
           padding: 0 20px;
         }
        h2{
            margin: 0 0 5px;
            font-size: 31px;
            @include media-breakpoint-down(sm){
                font-size: 22px;
             }
            &.work{
                display: flex;
                color:$white;
                font-size: 50px;
                @include media-breakpoint-down(sm){
                    font-size: 27px;
                 }
                h1{
                    font-size: 50px;
                    padding-left: 12px;
                    @include media-breakpoint-down(sm){
                        font-size: 27px;
                     }
                }
            }
        }
        p{
            @include media-breakpoint-down(sm){
                font-size: 14px;
             }
        }
    }
    .floatingIconsWrapper{
        @include media-breakpoint-down(md){
            position: absolute;
            bottom: 12%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
        .floatingIcon{
            align-items: center;
            display: flex;
            justify-content: center;
            position: absolute;
            animation: move-up-down 2s ease-in-out infinite alternate;
            // z-index: 2;
            @include media-breakpoint-down(md){
                z-index: 3;
                animation: none;
                display: block;
                left: unset !important;
                // width: 50px !important;
                // height: 50px !important;
                position: relative;
            }
            &:before{
                background-color: transparent;
                border: 1px solid #3c3c3c;
                border-radius: 100%;
                content: "";
                height: 40rem;
                width: 40rem;
                pointer-events: none;
                position: absolute;
                transition: .3s cubic-bezier(.23,1,.32,1);
                @include media-breakpoint-down(md){
                    width: 0 !important;
                    height: 0 !important;
                }
            }
            span{
                align-items: center;
                display: flex;
                justify-content: center;
                border-radius: 100%;
                transition: .3s cubic-bezier(.23,1,.32,1);
                @include media-breakpoint-down(md){
                    width: 60px !important;
                    height: 60px !important;
                }
                @include media-breakpoint-down(sm){
                    width: 40px !important;
                    height: 40px !important;
                }
                svg{
                    @include media-breakpoint-down(sm){
                        width: 15px !important;
                        height: 15px !important;
                     }
                }
            }
            &.github{
                animation-delay: -1333ms;
                left: calc(50% + 33rem);
                top: 40%;
                @include breakpoint(xlr){
                    left: calc(50% + 28rem);
                }
                span{
                    width: 70px;
                    height: 70px;
                    background-color: #525252;
                }
                &:hover{
                    span{
                        width: calc(70px * 1.3);
                        height: calc(70px * 1.3);
                    }
                    &:before{
                        width: calc(70px * 2.3);
                        height: calc(70px * 2.3);
                    }
                }
            }
            &.linkedIn{
                animation-delay: -666ms;
                left: calc(50% + 24rem);
                top: 79%;
                span{
                    width: 60px;
                    height: 60px;
                    background-color: #19394ad1;
                }
                &:before{
                    width: 20rem;
                    height: 20rem;
                }
                &:hover{
                    span{
                        width: calc(60px * 1.3);
                        height: calc(60px * 1.3);
                    }
                    &:before{
                        width: calc(60px * 2.3);
                        height: calc(60px * 2.3);
                    }
                }
            }
            &.gmail{
                animation-delay: -333ms;
                left: calc(50% + 8rem);
                bottom: 20%;
                span{
                    width: 45px;
                    height: 45px;
                    background: linear-gradient(59deg, #4285F459 0%,#BB001Bad 25%,#EA433578 50%,#FBBC0570 75%,#34A85359 100%);
                }
                &:before{
                    width: 27rem;
                    height: 27rem;
                }
                &:hover{
                    span{
                        width: calc(45px * 1.3);
                        height: calc(45px * 1.3);
                    }
                    &:before{
                        width: calc(45px * 2.3);
                        height: calc(45px * 2.3);
                    }
                }
            }
            &.instagram{
                left: calc(50% + -10rem);
                bottom: 17%;
                span{
                    width: 45px;
                    height: 45px;
                    background: linear-gradient(45deg, #f0943359 0%,#e6683c59 25%,#dc274359 50%,#cc236659 75%,#bc188859 100%);
                }
                &:before{
                    width: 24rem;
                    height: 24rem;
                }
                &:hover{
                    span{
                        width: calc(45px * 1.4);
                        height: calc(45px * 1.4);
                    }
                    &:before{
                        width: calc(45px * 2.4);
                        height: calc(45px * 2.4);
                    }
                }
            }
        }
    }
}

.aboutUsWrapper{
    padding: 90px 0;
    position: relative;
    background: url('https://res.cloudinary.com/docy5lucs/image/upload/v1632340482/pw/background/aboutBackground_ddsi07.png');
    background-repeat: no-repeat;
    background-position: center;
    // &::before{
    //     background: url('../bgDotted.png');
    //     content: "";
    //     position: absolute;
    //     z-index: 0;
    //     opacity: 0.2;
    //     width: 45%;
    //     height: 100%;
    //     right: 0;
    //     top: 0;
    // }
    .about{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
        .content{
            position: relative;
            max-width: 38%;
            flex-basis: 38%;
            @include breakpoint(xlr){
                max-width: 45%;
                flex-basis: 45%;
            }
            @include media-breakpoint-down(md){
                max-width: 100%;
                flex-basis: 100%;
            }
            p{
                font-size: 17px;
                line-height: 26px;
            }
            .signature{
                height: 50px;
                transform: rotate(-5deg);
                display: block;
                margin-left: auto;
                // position: absolute;
                right: 0;
                margin-top: 20px;
            }
            button{
                margin-top: 20px;
            }
        }
        .wordCloud{
            max-width: 58%;
            flex-basis: 58%;
            @include breakpoint(xlr){
                max-width: 53%;
                flex-basis: 53%;
            }
            @include media-breakpoint-down(md){
                max-width: 100%;
                flex-basis: 100%;
            }
            #tagcloud{
                @include breakpoint(xlr){
                    // transform: scale(0.8);
                }
                @include media-breakpoint-down(md){
                //    transform: scale(1);
                   margin: 0 auto;
                }
            }
            ul{
                li{
                    a{
                        font-family: 'Jost',sans-serif;
                        font-weight: 500;
                        letter-spacing: 1px;
                        background: color(secondary);
                        background-image: linear-gradient(
                    135deg
                    , color(primary) 40%, color(secondary) 74%) !important;
                        -webkit-background-clip: text !important;
                        -webkit-text-fill-color: transparent !important;
                    }
                }
            }
        }
    }
}

.portfolioOverWrapper{
    padding: 60px 0;
    // background: url('../bgTwo.png');
    background-repeat: no-repeat;
    background-position: center;
    .siteTitleWrapper{
        max-width: 60%;
        flex-basis: 60%;
        margin: 0 auto;
        @include media-breakpoint-down(md){
            max-width: 100%;
            flex-basis: 100%;
        }
        .siteTitle{
            text-align: center;
        }
        p{
            font-size: 18px;
            text-align: center;
            line-height: 26px;
            text-align: center;
            @include media-breakpoint-down(sm){
                font-size: 16px;
            }
        }
    }
    .portfolioWrapper{
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        // &::before{
        //     position: absolute;
        //     content: "";
        //     height: 100%;
        //     width: 1px;
        //     background: #ffffff17;
        //     left: 0;
        //     right: 0;
        //     display: block;
        //     margin: 0 auto;
        // }
    }
    .portfolioItem{
        max-width: 30.5%;
        position: relative;
        margin: 0 0 40px;
        flex-basis: 30.5%;
        @include media-breakpoint-down(md){
            max-width: 47%;
            flex-basis: 47%;
        }
        @include media-breakpoint-down(sm){
            max-width: 100%;
            flex-basis: 100%;
        }
        // &:nth-child(2n){
        //     margin-left: auto;
        //     margin-right: 20px;
        //     .content{
        //         // transform: rotate(
        //         //     90deg) translateY(-58%);
        //     }
        // }
        // &:nth-child(2n+1){
        //     margin-left: 20px;
        //     .content{
        //         left: -29%;
        //         // transform: rotate(
        //         //     -90deg) translateY(-58%);
        //     }
        // }
        .content{
            // padding: 40px 20px 0;
            position: absolute;
            top: 42%;
            display: block;
            opacity: 0.6;
            h3{
                font-size: 40px;
                margin: 0;
            }
            p{
                font-family: 'Jost', sans-serif;
                padding: 6px 0 0;
                margin: 0;
            }
        }
        
        // img{
        //     width: 100%;
        //     border-radius: 6px;
        // }
        .react-image-shadow{
            width: 100% !important;
            img{
                width: 100% !important;
            }
        }
    }
}

.blogsWrapper{
    padding: 60px 0;
    position: relative;
    // background: url('../bg.png');
    // background-repeat: no-repeat;
    // background-position: center;
    .siteTitleWrapper{
        max-width: 60%;
        flex-basis: 60%;
        @include media-breakpoint-down(md){
            max-width: 100%;
            flex-basis: 100%;
        }
        margin: 0 auto 25px;
        .siteTitle{
            text-align: center;
        }
        p{
            font-size: 18px;
            text-align: center;
            line-height: 26px;
            @include media-breakpoint-down(sm){
                font-size: 16px;
            }
        }
    }
    .column{
        max-width: 32%;
        flex-basis: 32%;
        @include media-breakpoint-down(md){
            max-width: 47%;
            flex-basis: 47%;
            margin-bottom: 25px;
        }
        @include media-breakpoint-down(sm){
            max-width: 100%;
            flex-basis: 100%;
        }
        .blogsBlock{
            min-height: 525px;
            box-shadow: 0 10px 30px 4px rgb(37 41 44 / 26%);
            border-radius: 8px;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            img{
                max-width: 100%;
            }
            .content{
                h3{
                    font-size: 24px;
                    transition: all 0.4s ease-in;
                    background-image: linear-gradient(174deg, #6d14ff 4%, #04acec 74%) !important;
                    -webkit-background-clip: text !important;
                    -webkit-text-fill-color: transparent !important;
                    margin: 0;
                }
                padding: 20px;
            }
            &:hover{
                border-radius: 3px;
                transition: all 0.4s ease;
                transform: translateY(-10px);
            }
        }
    }
}