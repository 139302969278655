@import "./breakpoints", "./_functions", "./_variables";

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');

@-webkit-keyframes shine{
    0%{
        opacity:0;
        left:-50%
    }
    20%{
        opacity:0.3;
        left:0
    }
    30%{
        opacity:0.45;
        left:5%
    }
    50%{
        opacity:0.45;
        left:15%
    }
    80%{
        opacity:0.3;
        left:30%
    }
    to{
        opacity:0;
        left:50%
    }
}

@keyframes shine{
    0%{
        opacity:0;
        left:-50%
    }
    20%{
        opacity:0.3;
        left:0
    }
    30%{
        opacity:0.45;
        left:5%
    }
    50%{
        opacity:0.45;
        left:15%
    }
    80%{
        opacity:0.3;
        left:30%
    }
    to{
        opacity:0;
        left:50%
    }
}

@keyframes move {
  0% { clip-path: ellipse(45% 50% at 50% 50%); }
	15% { clip-path: ellipse(47% 43% at 50% 56%);  }
	30% { clip-path: ellipse(35% 42% at 55% 50%); }
	45% { clip-path: ellipse(40% 30% at 50% 45%); }
	60% { clip-path: ellipse(37% 30% at 45% 50%); }
	75% { clip-path: ellipse(40% 30% at 50% 35%); }
  100% { clip-path: ellipse(45% 50% at 50% 50%); }
}

@keyframes blobber {
  0% {
    border-radius: 48% 52% 68% 32%/42% 28% 72% 58%;
  }
  5% {
    border-radius: 48% 52% 41% 59%/66% 37% 63% 34%;
  }
  10% {
    border-radius: 69% 31% 71% 29%/67% 31% 69% 33%;
  }
  15% {
    border-radius: 60% 40% 71% 29%/63% 65% 35% 37%;
  }
  20% {
    border-radius: 61% 39% 51% 49%/57% 58% 42% 43%;
  }
  25% {
    border-radius: 66% 34% 25% 75%/47% 30% 70% 53%;
  }
  30% {
    border-radius: 32% 68% 38% 62%/65% 60% 40% 35%;
  }
  35% {
    border-radius: 63% 37% 41% 59%/35% 38% 62% 65%;
  }
  40% {
    border-radius: 57% 43% 49% 51%/55% 71% 29% 45%;
  }
  45% {
    border-radius: 47% 53% 34% 66%/65% 36% 64% 35%;
  }
  50% {
    border-radius: 44% 56% 32% 68%/69% 26% 74% 31%;
  }
  55% {
    border-radius: 28% 72% 37% 63%/71% 44% 56% 29%;
  }
  60% {
    border-radius: 38% 62% 35% 65%/74% 53% 47% 26%;
  }
  65% {
    border-radius: 73% 27% 46% 54%/54% 47% 53% 46%;
  }
  70% {
    border-radius: 75% 25% 47% 53%/49% 53% 47% 51%;
  }
  75% {
    border-radius: 62% 38% 43% 57%/55% 60% 40% 45%;
  }
  80% {
    border-radius: 41% 59% 65% 35%/73% 50% 50% 27%;
  }
  85% {
    border-radius: 55% 45% 57% 43%/73% 61% 39% 27%;
  }
  90% {
    border-radius: 74% 26% 33% 67%/40% 65% 35% 60%;
  }
  95% {
    border-radius: 58% 42% 57% 43%/53% 45% 55% 47%;
  }
  100% {
    border-radius: 48% 52% 68% 32%/42% 28% 72% 58%;
  }
}
@keyframes cobler {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
@keyframes wobbler {
  0% {
    transform: rotateZ(8deg);
  }
  100% {
    transform: rotateZ(-8deg);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-size: 16px;
  background-color:  #0f121a;
  font-family: 'Nunito Sans', sans-serif;
  color: $white;
  &.menuActive{
    overflow-y: hidden;
    height: 100vh;
  }
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 500;
  margin-top: 0;
  color: $white;
  letter-spacing: 1px;
  font-family: 'Jost',sans-serif;
}

a {
  color: $white;
  display: block;
  text-decoration: none;
  &:hover,
  &:focus {
    outline: none;
  }
}

//input fields

input,
select,
textarea {
  width: 100%;
  outline: none;
  border: 1.5px solid #2c3d5033;
  margin-bottom: 20px;
  padding: 12px 15px;
  font-size: 15px;
  &:hover,
  &:focus {
    border: 1.5px solid #2c3d507e;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sectionWrapper {
  max-width: 1280px;
  width: 90%;
  padding: 0 20px;
  margin: 0 auto;
  // position: relative;
  z-index: 2;
}

.row{
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.siteHeader{
  position: absolute;
  width: 100%;
  // z-index: 999;
  padding: 20px 0;
  .col{
    max-width: 50%;
    flex-basis: 50%;
  }
  &.stickyTop{
    position: fixed;
    width: 100%;
    padding: 15px 0;
    background: #0f121a9e;
    box-shadow: 0 10px 30px 4px rgb(26 28 31 / 51%);
    backdrop-filter: blur(60px);
    z-index: 1100;
    .logoWrapper{
      img{
        height: 40px;
      }
    }
  }
  .logoWrapper{
    z-index: 5;
    img{
      display: block;
      height: 50px;
    }
  }
  .toggle-menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 50px;
    outline: none;
    padding: 0;
    pointer-events: initial;
    position: relative;
    vertical-align: middle;
    width: 50px;
    z-index: 1110;
    span {
      background-color: #fff;
      content: "";
      display: block;
      height: 2px;
      left: calc(50% - 13px );
      position: absolute;
      top: calc(50% - 1px );
      transform-origin: 50% 50%;
      transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
      width: 26px;
      &:before,&:after{
        background-color: #fff;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transform-origin: 50% 50%;
        transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
        width: 26px;
      }
      &:before{
        top: 7px;
      }
      &:after{
        top: -7px;
      }
    }
    &.active{
      span{
        background-color: transparent;
        transition: background 0.2s ease-out;
        &::before,&:after{
          transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
        }
        &:before{
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }
        &:after{
          top: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    }
  }
}

#menu {
  backdrop-filter: blur(60px);
  background: #0f121a9e;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1100;
  &.open{
    opacity: 1;
    visibility: visible;
  }
  .imgWrap{
    width: 60vw;
    height: 80vh;
    left: 2%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    img{
      animation: blobber 30s 0s linear infinite, cobler 30s 0s linear infinite alternate;
    }
    svg,img{
      height: 500px;
      width: 650px;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: 0 auto;
    }
    @include media-breakpoint-down(md){
      display: none;
    }
  }
  .menuWrapper{
    position: absolute;
    // width: 100%;
    top: 50%;
    right: 28%;
    transform: translateY(-50%);
    @include breakpoint(xlr){
      right: 15%;
    }
    @include media-breakpoint-down(md){
      width: 100%;
      right: 0;
      left: 0;
     }
    // padding-left: 100px;
    .menuItem{
      // position: relative;
      text-align: left;
      font-size: 48px;
      font-family: 'Jost', sans-serif;
      padding: 0 0 5px;
      letter-spacing: 1px;
      transition-delay: 0.25s;
      transition: all 650ms cubic-bezier(0.77, 0, 0.175, 1);
      @include media-breakpoint-down(md){
        text-align: center;
       }
      &:last-child{
        padding: 0;
      }
      a{
        color: rgba(255, 255, 255, 0.5);
        width: max-content;
        position: relative;
        overflow: hidden;
        display: inline-block;
        .menuItemMask{
          position: absolute;
          overflow: hidden;
          // width: 100%;
          transform: translateX(-102%);
          transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
          span{
            color: $white;
            transform: translateX(102%);
            display: inline-block;
            transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
          }
        }
        &:hover{
          .menuItemMask{
            transform: translateY(0%);
            transition: all 350ms cubic-bezier(0.77, 0, 0.175, 1);
            span{
              transform: translateY(0%);
              transition: all 350ms cubic-bezier(0.77, 0, 0.175, 1);
            }
          }
        }

      }
      &:nth-child(1){
       .fakeLink{
         &::before{
          content: 'Home';
         }
       }
      }
      &:nth-child(2){
       .fakeLink{
         &::before{
          content: 'About';
         }
       }
      }
      &:nth-child(3){
       .fakeLink{
         &::before{
          content: 'Portfolio';
         }
       }
      }
      &:nth-child(4){
       .fakeLink{
         &::before{
          content: 'Contact';
         }
       }
      }
    }
  }
}

.siteTitle{
  font-size: 50px;
  margin-bottom: 0;
  background: color(secondary);
  background-image: linear-gradient(174deg, #6d14ff 4%, #04acec 74%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  @include media-breakpoint-down(sm){
    font-size: 38px;
  }
}

.tag{
  color:#515152;
  font-family: 'La Belle Aurore', cursive;
  font-style: italic;
  margin: 0;
  font-size: 18px;
  line-height: 0;
}

.siteBtn{
  position: relative;
  width: 175px;
  overflow: hidden;
  height: 55px;
  transform: all 0.4s ease-in;
  margin: 40px auto 0;
  // padding: 17px 25px;
  border-radius: 10px;
  background: linear-gradient(174deg, #6d14ff 4%, #04acec 74%);
  color: $white;
  .present,.future{
    position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
    width: 100%;
    height: 100%;
  }
  .present{
    transform: scale(1);
    transition: all 0.4s ease-in;
    opacity: 1;
  }
  .future{
    transform: scale(0);
    transition: all 0.4s ease-in;
    opacity: 0;
  }
  &:hover{
    transition: all 0.4s ease-in;
    border-radius: 4px;
    .present{
      transform: scale(2);
      transition: all 0.4s ease-in;
      opacity: 0;
    }
    .future{
      transform: scale(1);
      transition: all 0.4s ease-in;
      opacity: 1;
    }
  }
}

footer{
  background: url('https://res.cloudinary.com/docy5lucs/image/upload/v1632340467/pw/background/footerBackground_smszcd.png');
  background-size: cover;
  background-position: center;
  .mainFooter{
    padding: 60px 0 100px;
    .row{
      padding: 80px 0 0;
      align-items: center;
      justify-content: space-between;
      .col{
        max-width: 50%;
        flex-basis: 50%;
        @include media-breakpoint-down(sm){
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      h2{
        font-size: 50px;
        margin: 0;
        letter-spacing: 2px;
        @include media-breakpoint-down(sm){
          font-size: 45px;
        }
        span{
          display: block;
          padding-left: 65px;
        }
      }
      .typeFormBtn{
        margin-left: auto;
         @include media-breakpoint-down(sm){
          margin:  40px auto 0;
         }
      }
    }
    .socialMediaWrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 60px;
      a{
        max-width: 18%;
        border-radius: 8px;
        flex-basis: 18%;
        position: relative;
        perspective: 1000px;
        @include media-breakpoint-down(sm){
          max-width: 47%;
          flex-basis: 47%;
          margin: 0 0 20px;
        }
        .socialMediaBlockInner{
          box-shadow: 0 10px 30px 4px rgba(26, 28, 31, 0.51);
          position: relative;
          width: 100%;
          height: 100%;
          min-height: 125px;
          transform-style: preserve-3d;
          transition: all 0.8s ease;
          border-radius: 8px;
          &.github{
            background: #24292f;
          }
          &.linkedIn{
            background: #0a66c2;
          }
          &.instagram{
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 

          }
          &.medium{
            background: #1b1b1b;
          }
          &.gmail{
            background: linear-gradient(90deg, #3e65cf 0%,#c71610 25%,#f2a60c 50%,#3b60c4 75%,#08851b 100%); 
          }
        }
        &:hover{
          .socialMediaBlockInner{
            transform: rotateY(-180deg);
            .front{
              opacity: 0;
            }
            .back{
              opacity: 1;
            }
          }
        }
        .front,.back{
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .front{
          svg,img{
            transform: translateZ(40px);
            width: auto;
            height: 30px;
          }
        }
        .back{
          transform: rotateY(-180deg);
          z-index: 2;
          opacity: 0;
          p{
            transform: translateZ(40px);
            color: $white;
            text-align: center;
            font-family: 'Jost',sans-serif;
            @include media-breakpoint-down(md){
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .absoluteFooter{
    padding: 16px 0 12px;
    border-top: 1px solid #ffffff33;
    p{
      opacity: 0.7;
      font-family: "La Belle Aurore", cursive;
      letter-spacing: 1px;
      margin: 0;
      font-weight: 600;
      text-align: center;
    }
  }
}

.typeFormBtn{
  all:unset;
  font-family:'Nunito Sans',sans-serif;
  display:block;
  max-width:100%;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  background:linear-gradient(102deg, #370982 4%, #076a90 74%);
  color:$white;
  font-size:17px;
  letter-spacing: 1px;
  border-radius:4px;
  padding:15px 33px;
  font-weight:500;
  // height:50px;
  cursor:pointer;
  // line-height:50px;
  text-align:center;
  margin:0;
  text-decoration:none;
}